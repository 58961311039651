import React, { useContext, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { GameStateContext } from "../store/GameState"
import BoardController from "../components/board/BoardController"
import { EGameMode } from "../types/EGameMode"
import { ICellCoordinates } from "../types/IBoardState"
import Controls from "../components/Controls"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import HexaGoLogo from "../components/HexaGoLogo"
import { connectToRoom, createNewRoom, IRoomConnection, sendMessage } from "../lib/Multiplayer"

const Play = observer(() => {
    const gameState = useContext(GameStateContext)
    const { room } = useParams<{ room: string }>()    
    const [roomConnection, setRoomConnection] = useState<IRoomConnection>()

    const [gameMode, setGameMode] = useState<EGameMode>(EGameMode.LOCAL)
    useEffect(() => {
        if (room) {
            setRoomConnection(connectToRoom(room, gameState!.handleMultiplayerMove.bind(gameState)))
            setGameMode(EGameMode.MULTIPLAYER_CLIENT)
        }
    }, [gameState, room])

    const createRoom = async () => {
        setRoomConnection(await createNewRoom(gameState!.handleMultiplayerMove.bind(gameState)))
        setGameMode(EGameMode.MULTIPLAYER_HOST)
    }

    const handleNewGame = () => {
        gameState!.newGame({ size: 11 })
        if (gameMode !== EGameMode.LOCAL) {
            sendMessage('newGame', roomConnection!)
        }
    }

    const handlePass = () => {
        gameState!.pass()
        if (
            gameMode === EGameMode.MULTIPLAYER_HOST ||
            gameMode === EGameMode.MULTIPLAYER_CLIENT
        ) {
            sendMessage('pass', roomConnection!)
        }
    }

    const handleMove = ({ x, y }: ICellCoordinates) => {
        if (gameState!.makeMove({ x, y })) {
            if (
                gameMode === EGameMode.MULTIPLAYER_HOST ||
                gameMode === EGameMode.MULTIPLAYER_CLIENT
            ) {
                sendMessage(`move: ${x}; ${y}`, roomConnection!)
            }
        }
    }

    const handleLeaveRoom = () => {
        setGameMode(EGameMode.LOCAL)
        setRoomConnection(undefined)
        handleNewGame()
    }

    return gameState && <div>
        <Row>
            <Col md="auto">
                <HexaGoLogo />
                <Controls
                    isFinished={gameState.isFinished}
                    mode={gameMode}
                    newGame={handleNewGame}
                    pass={handlePass}
                    playerAScore={gameState.playerAScore}
                    playerBScore={gameState.playerBScore}
                    turn={gameState.turn}
                    roomId={roomConnection?.roomId}
                    createRoom={createRoom}
                    joinRoom={() => console.log('Not implemented: Join room')}
                    leaveRoom={handleLeaveRoom}
                />
            </Col>
            <Col>
                <div style={{ width: 'fit-content', margin: 'auto' }}>
                    <BoardController
                        board={gameState.board}
                        handleCellClick={handleMove}
                        mode={gameMode}
                        turn={gameState.turn}
                    />
                </div>
            </Col>
            {gameMode === EGameMode.LOCAL
                ? <div />
                : <Col md="auto">
                    {/* <div style={{ width: '250px' }}>
                        <h4>Chat:</h4>
                    </div> */}
                </Col>
            }
        </Row>
    </div>
})

export default Play
