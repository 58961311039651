import React, { useMemo } from "react"
import { TBoard } from "../../types/IBoardState"
import HexRow from "./HexRow"
import '../../styles/hex.scss';

export interface BoardProps {
    board: TBoard,
    outerClassNames: string[][][]
    innerClassNames: string[][][]
    handleCellClick: Function
    handleCellHover: Function
    disabled: boolean
}

const Board: React.FunctionComponent<BoardProps> = ({
    board,
    handleCellClick,
    handleCellHover,
    outerClassNames,
    innerClassNames,
    disabled,
}) => {
    const mappedOuterClassNames = useMemo(() => {
        let out = outerClassNames.map((row, y) => row.map((cell, x) => {
            if (board[y][x] !== null) return [...cell, 'valid']
            return cell
        }))
        if (disabled) {
            out = out.map(row => row.map(cell => [...cell, 'disabled']))
        }
        return out
    }, [board, disabled, outerClassNames])

    const mappedInnerClassNames = useMemo(() =>
        disabled
            ? innerClassNames.map(row => row.map(cell => [...cell, 'disabled']))
            : innerClassNames,
        [disabled, innerClassNames]
    )

    return board && <div>
        {board.map((row, y) =>
            <HexRow
                key={`Row-${y}`}
                row={row}
                y={y}
                handleCellClick={handleCellClick}
                handleCellHover={handleCellHover}
                rowOuterClassNames={mappedOuterClassNames[y]}
                rowInnerClassNames={mappedInnerClassNames[y]}
            />
        )}
    </div>
}

export default Board
