import { makeAutoObservable } from "mobx"
import { createGameState, IGameState, makeMove, pass } from "../lib/GoEngine";
import { ArgI } from 'tsargs'
import { TBoard } from "../types/IBoardState";
import { createContext } from "react";

export class GameState {
    state!: IGameState;
    debug = false

    constructor(data: ArgI<typeof createGameState, 0>, debug = false) {
        this.debug = debug
        makeAutoObservable(this)
        this.newGame(data)
    }

    makeMove(coords: ArgI<typeof makeMove, 1>): boolean {
        const result = makeMove(this.state, coords, this.debug)
        if (result) {
            this.state = result
        }
        return !!result
    }

    pass() {
        this.state = pass(this.state, this.debug)
    }

    newGame(data: ArgI<typeof createGameState, 0>) {
        this.state = createGameState(data, this.debug)
    }

    setDebug(debug: boolean) {
        this.debug = debug
    }

    get turn(): boolean {
        return this.state.turn
    }

    get board(): TBoard {
        return this.state.board
    }

    get playerAScore(): number {
        return this.state.playerAScore
    }

    get playerBScore(): number {
        return this.state.playerBScore
    }

    get isFinished(): boolean {
        return this.state.gameFinished
    }

    handleMultiplayerMove(move: string) {
        console.log('Multiplayer:', move)
        if (move === 'pass') {
            this.pass()
        } else if (move.startsWith('move:')) {
            const [x, y] = move.split(':')[1].split(';').map(n => Number(n))
            this.makeMove({ x, y })
        } else if (move === 'newGame') {
            this.newGame({ size: 11 })
        }
    }
}

export const GameStateContext = createContext<GameState | null>(null)
