import React from "react";
import { Link } from "react-router-dom";

export interface HexaGoLogoProps {
    big?: boolean
    link?: boolean
}

const HexaGoLogo: React.FunctionComponent<HexaGoLogoProps> = ({
    big = false,
    link = true,
}) => {
    const hex = <span style={{ fontWeight: 'bold' }}>⬡</span>
    const hexaGoText = 'HexaGo'
    const n = <span style={{ opacity: 0.1 }}>n</span>
    const logo = big ? <h1>{hex} {hexaGoText}{n}</h1> : <h2>{hex} {hexaGoText}{n}</h2>
    return link
        ? <Link to="/" style={{ textDecoration: 'none' }}>{logo}</Link>
        : <div>{logo}</div>
}

export default HexaGoLogo
