import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HexaGoLogo from "../components/HexaGoLogo"
import { faPlus, faUserFriends, faPlay } from '@fortawesome/free-solid-svg-icons'

export interface MainProps {

}

const Main: React.FunctionComponent<MainProps> = () => {
    return <Container>
        <HexaGoLogo big={true} link={false} />
        <Row>
            <Col>
                <h3>About:</h3>
                <div className="description mb-3">
                    Basically a normal Go game, but on a hexagonal
                    board
                    <br />
                    This game uses chinese rules, so any group
                    is alive unless explictly killed
                    <br />
                    <i>I think</i> the Ko situation is not possible
                    on this board, if you can create a Ko situation,
                    please email me at
                    <a href="mailto:ilya.ruwork@gmail.com" className="ml-1">
                        ilya.ruwork@gmail.com
                    </a>
                </div>
                <h3>Credits:</h3>
                <div className="credits mb-3">
                    <div>
                        <span className="mr-1">
                            Created by:
                    </span>
                        <a href="https://github.com/Gibitop">
                            Ilya Nikishin
                    </a>
                    </div>
                    <div>
                        <span className="mr-1">
                            Inspired by:
                    </span>
                        <a href="https://www.youtube.com/watch?v=thOifuHs6eY">
                            "Hexagons are the Bestagons"
                    </a>
                    </div>
                </div>
            </Col>
            <Col>
                <h3>How to play:</h3>
                <div className="mb-3">
                    <ol>
                        <li>Place circles inside hexagons</li>
                        <li>Encslose empty territory to get points</li>
                        <li>
                            Kill groups of enemy circles by closing all 
                            the empty cells around the group
                        </li>
                        <li>
                            Get points for killing enemy groups
                        </li>
                        <li>
                            If you think, you can't improve your position
                            or there is nothing left to do on the board,
                            you may skip your move by pressing a "pass" button
                        </li>
                        <li>
                            After two successive passes game is finished
                            and final scores are calculated
                        </li>
                        <li>
                            You will receive additional points for any
                            empty territory enclosed by your groups
                        </li>
                    </ol>
                </div>
                <h3 className="mb-3">Start playing:</h3>
                <div className="w-100">
                    <Link to="/play" className="w-100">
                        <Button className="w-100 mb-2">
                            <FontAwesomeIcon icon={faPlay} className="mr-2" />
                            Play locally
                        </Button>
                    </Link>
                    <br />
                    <Button variant="success" className="w-100 mb-2">
                        <FontAwesomeIcon icon={faPlus} className="mr-2" />
                        Create a room
                    </Button>
                    <br />
                    <Button variant="warning" className="w-100">
                        <FontAwesomeIcon icon={faUserFriends} className="mr-2" />
                        Join a room
                    </Button>
                </div>
            </Col>
        </Row>
    </Container>
}

export default Main;
