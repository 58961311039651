import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { GameState, GameStateContext } from "./store/GameState";
import Main from "./views/Main";
import Play from "./views/Play";


export interface AppProps {
    defaultGameState: GameState
}

const App: React.FunctionComponent<AppProps> = ({
    defaultGameState
}) => {
    return (
        <div className="app">
            <GameStateContext.Provider value={defaultGameState}>
                <Router>
                    <Switch>
                        <Route path="/" exact={true}>
                            <Main />
                        </Route>
                        <Route path="/play/:room">
                            <Play />
                        </Route>
                        <Route path="/play">
                            <Play />
                        </Route>
                    </Switch>
                </Router>
            </GameStateContext.Provider>
        </div>
    )
}

export default App
