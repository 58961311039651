import React from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { EGameMode } from "../types/EGameMode";

export interface ControlsProps {
    isFinished: boolean
    turn: boolean
    playerAScore: number
    playerBScore: number
    newGame: Function
    pass: Function
    createRoom: Function
    joinRoom: Function
    leaveRoom: Function
    roomId: string | undefined
    mode: EGameMode
}

const Controls: React.FunctionComponent<ControlsProps> = ({
    isFinished,
    turn,
    playerAScore,
    playerBScore,
    newGame,
    pass,
    createRoom,
    leaveRoom,
    roomId,
    mode,
    joinRoom,
}) => {

    const turnWord = mode === EGameMode.MULTIPLAYER_HOST
        ? turn ? 'your' : 'your oponent\'s'
        : mode === EGameMode.MULTIPLAYER_CLIENT
            ? turn ? 'your oponent\'s' : 'your'
            : turn ? 'blue\'s' : 'red\'s'

    const playerAScoreText = mode === EGameMode.MULTIPLAYER_HOST
        ? 'Your'
        : mode === EGameMode.MULTIPLAYER_CLIENT
            ? 'Oponent\'s'
            : 'Blue\'s'

    const playerBScoreText = mode === EGameMode.MULTIPLAYER_HOST
        ? 'Oponent\'s'
        : mode === EGameMode.MULTIPLAYER_CLIENT
            ? 'Your'
            : 'Red\'s'

    const canPass = mode === EGameMode.LOCAL || (mode === EGameMode.MULTIPLAYER_HOST) === turn

    const roomLink = `${window.location.origin}${window.location.pathname}/${roomId}`

    return (
        <div className="sidebar">
            <div className="mb-3">
                {roomId
                    ? <div>
                        {mode === EGameMode.MULTIPLAYER_HOST
                            ? <div>
                                You are in <b>multiplayer mode</b> <br />
                                Your room link is:<br />
                                <InputGroup className="mt-2">
                                    <FormControl
                                        size="sm"
                                        disabled={true}
                                        value={roomLink}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            size="sm"
                                            variant="outline-info"
                                            onClick={() => navigator.clipboard.writeText(roomLink)}
                                        >
                                            Copy
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                            : <div className="mb-2">
                                You have joined multiplayer a room
                            </div>
                        }
                        <Button
                            className="mt-2"
                            size="sm"
                            variant="outline-primary"
                            onClick={() => leaveRoom()}
                        >
                            Leave room
                        </Button>
                    </div>
                    : <div>
                        You are in <b>local</b> mode <br />
                        <Button
                            size="sm"
                            variant="outline-primary"
                            className="mt-2"
                            onClick={() => createRoom()}
                        >
                            Create a room
                        </Button>
                        <Button
                            size="sm"
                            variant="outline-primary"
                            className="mt-2"
                            onClick={() => joinRoom()}
                        >
                            Join a room
                        </Button>
                    </div>
                }
            </div>


            { !isFinished
                ? <h4>It's {turnWord} turn!</h4>
                : <h3>Game is finished</h3>
            }

            <h5>{playerAScoreText} score: {playerAScore}</h5>
            <h5>{playerBScoreText} score: {playerBScore}</h5>

            {/* Viewers can't controll game state */}
            { mode === EGameMode.VIEWER
                ? <div />
                : <div>
                    {/* Client can't create a new game */}
                    {mode === EGameMode.MULTIPLAYER_CLIENT
                        ? <div />
                        : <Button
                            className="mt-3"
                            onClick={() => newGame()}
                            variant="success"
                        >
                            New game
                        </Button>
                    }
                    <br />
                    <Button
                        className="mt-2"
                        onClick={() => pass()}
                        variant="warning"
                        disabled={isFinished || !canPass}
                    >
                        Pass
                    </Button>
                </div>
            }
        </div>
    );
}

export default Controls;
