import React, { useMemo, useState } from "react"
import { EGameMode } from "../../types/EGameMode"
import { analizeGroup } from "../../lib/GoEngine"
import { ECellState, ICellCoordinates, TBoard } from "../../types/IBoardState"
import Board from "./Board"

export interface BoardControllerProps {
    board: TBoard
    turn: boolean
    mode: EGameMode
    handleCellClick: Function
}

const BoardController: React.FunctionComponent<BoardControllerProps> = ({
    board,
    turn,
    mode,
    handleCellClick,
}) => {
    const disabled = useMemo<boolean>(() => {
        if (mode === EGameMode.VIEWER) return true
        if (mode === EGameMode.LOCAL) return false
        return (mode === EGameMode.MULTIPLAYER_HOST) !== turn
    }, [turn, mode])

    const [hoveredCell, setHoveredCell] = useState<ICellCoordinates | null>(null)
    const groupInfo = useMemo<ReturnType<typeof analizeGroup>>(() => {
        if (!hoveredCell) return { group: [], dames: [] }
        return analizeGroup(hoveredCell, board)
    }, [board, hoveredCell])

    const mappedHandleCellClick = useMemo(
        () => disabled ? (() => { }) : handleCellClick,
        [disabled, handleCellClick]
    )

    const outerClassNames = useMemo<string[][][]>(() => {
        const out = []
        for (let y = 0; y < board.length; y++) {
            out.push([])
            for (let x = 0; x < board[y].length; x++) {
                (out[y] as string[][]).push([])

                if (groupInfo.group.some(({ x: _x, y: _y }) => x === _x && y === _y)) {
                    (out[y][x] as string[]).push('group')
                }
                if (groupInfo.dames.some(({ x: _x, y: _y }) => x === _x && y === _y)) {
                    (out[y][x] as string[]).push('dame')
                }
            }
        }
        return out
    }, [board, groupInfo])

    const innerClassNames = useMemo(() => {
        const out = []
        for (let y = 0; y < board.length; y++) {
            out.push([])
            for (let x = 0; x < board[y].length; x++) {
                (out[y] as string[][]).push([])

                if (board[y][x] !== ECellState.EMPTY) {
                    let aPlayer = board[y][x] === ECellState.PLAYER_A
                    if (mode === EGameMode.MULTIPLAYER_CLIENT) {
                        aPlayer = !aPlayer
                    }
                    if (aPlayer) {
                        (out[y][x] as string[]).push('player', 'player-a')
                    } else {
                        (out[y][x] as string[]).push('player', 'player-b')
                    }
                }
            }
        }
        return out
    }, [board, mode])

    return (
        <Board
            board={board}
            disabled={disabled}
            handleCellClick={mappedHandleCellClick}
            handleCellHover={setHoveredCell}
            innerClassNames={innerClassNames}
            outerClassNames={outerClassNames}
        />
    )
}

export default BoardController
