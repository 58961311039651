export interface ICellCoordinates {
    x: number
    y: number
}

export enum ECellState {
    EMPTY,
    PLAYER_A,
    PLAYER_B,
}

export type TBoard = (ECellState | null)[][]
