import _ from "lodash";
import { ECellState, ICellCoordinates, TBoard } from "../types/IBoardState";
import { getNeighbourCoordinates, makeBoard } from "./Board";

export const version = '3.1.0'

export interface IGameState {
    board: TBoard
    playerAScore: number
    playerBScore: number
    turn: boolean
    playerAPassed: boolean
    playerBPassed: boolean
    gameFinished: boolean
}

interface IEngineConstructor {
    turn?: boolean
    playerAScore?: number
    playerBScore?: number
}

interface ICreateGameStateFromBoard extends IEngineConstructor {
    board: TBoard
}

interface ICreateGameStateWithBoard extends IEngineConstructor {
    size: number
}

export function createGameState(
    data: (ICreateGameStateFromBoard | ICreateGameStateWithBoard),
    debug = false,
): IGameState {
    const out = {
        playerAPassed: false,
        playerBPassed: false,
        gameFinished: false,
    } as IGameState

    if ('board' in data) {
        out.board = _.cloneDeep(data.board)
        if (debug) {
            console.log('Creating a new game state from an existing board')
        }
    } else {
        out.board = makeBoard(data.size)
        if (debug) {
            console.log(`Creating a new game state from a new board of size ${data.size}`)
        }
    }
    out.turn = data.turn ?? true
    out.playerAScore = data.playerAScore ?? 0
    out.playerBScore = data.playerBScore ?? 0

    if (debug) {
        console.log(`Player ${data.turn ? 'A' : 'B'} starts`)
        console.log(`Starting player A score: ${data.playerAScore}`)
        console.log(`Starting player B score: ${data.playerBScore}`)
    }

    return out
}


export function makeMove(
    state: IGameState,
    { x, y }: ICellCoordinates,
    debug = false
): IGameState | false {
    const _state = _.cloneDeep(state)

    if (debug) {
        console.log(`Move: Player ${_state.turn ? 'A' : 'B'} to (${x}; ${y})`)
    }

    if (_state.gameFinished) {
        if (debug) {
            console.log(`Invalid: Game has finished`)
        }
        return false
    }
    if (_state.board[y][x] === null) {
        if (debug) {
            console.log(`Invalid: Invalid cell`)
        }
        return false
    }
    if (_state.board[y][x] !== ECellState.EMPTY) {
        if (debug) {
            console.log(`Invalid: Cell is not empty`)
        }
        return false
    }

    const turnCellState = _state.turn ? ECellState.PLAYER_A : ECellState.PLAYER_B
    const oponentCellState = _state.turn ? ECellState.PLAYER_B : ECellState.PLAYER_A

    let isValid = false
    let invalidReason = ''

    let pointsGain = 0

    // Check if move is suicidal, normal or kills some groups
    for (const neighbour of getNeighbourCoordinates(_state.board, { x, y })) {
        // Check each direction
        if (_state.board[neighbour.y][neighbour.x] === ECellState.EMPTY) {
            // A normal move
            if (debug) {
                console.log('Next to an empty cell')
            }
            isValid = true
        } else {
            let { group, dames } = analizeGroup(neighbour, _state.board)
            if (_state.board[neighbour.y][neighbour.x] === oponentCellState && dames.length === 1) {
                // A killing move
                // We are taking the last dame from oponent's group
                if (debug) {
                    console.log(`Killing group at (${neighbour.x}; ${neighbour.y})`)
                }
                isValid = true
                // Kill this group
                for (const coords of group) {
                    _state.board[coords.y][coords.x] = ECellState.EMPTY
                    pointsGain++
                }
            } else {
                // A suicidal (invalid) move (at least in this direction)
                if (debug) {
                    invalidReason = 'suicidal move'
                }
            }
        }
    }

    _state.board[y][x] = turnCellState
    if (!isValid && analizeGroup({ x, y }, _state.board).dames.length > 0) {
        // Extension of a live group
        if (debug) {
            console.log("Extension of a live group")
        }
        isValid = true;
    }

    if (isValid) {
        if (_state.turn) {
            _state.playerAPassed = false
            _state.playerAScore += pointsGain
        } else {
            _state.playerBPassed = false
            _state.playerBScore += pointsGain
        }

        _state.turn = !_state.turn
        if (debug) {
            console.log(`Valid move`)
        }
    } else {
        if (debug) {
            console.log(`Invalid: ${invalidReason}`)
        }
    }

    return isValid && _state
}

export function pass(state: IGameState, debug = false): IGameState {
    const _state = _.cloneDeep(state)

    if (debug) {
        console.log(`Player ${_state.turn ? 'A' : 'B'} passed`)
    }
    _state.turn = !_state.turn
    if (_state.turn) {
        _state.playerAPassed = true
    } else {
        _state.playerBPassed = true
    }
    if (_state.playerAPassed && _state.playerBPassed) {
        if (debug) {
            console.log('Both players passed')
        }
        return finishGame(state, debug)
    }

    return _state
}

export function analizeGroup(
    { x, y }: ICellCoordinates,
    board: TBoard,
    group: ICellCoordinates[] = [],
    dames: ICellCoordinates[] = [],
    player: boolean | null = null,
): {
    group: ICellCoordinates[],
    dames: ICellCoordinates[]
} {
    // If already visited
    if (group.some(({ x: _x, y: _y }: ICellCoordinates) => x === _x && y === _y)) {
        return { group, dames }
    }
    // If the cell is empty or invalid
    if (board[y][x] === null || board[y][x] === ECellState.EMPTY) {
        return { group, dames }
    }

    // If first run
    if (player === null) {
        player = board[y][x] === ECellState.PLAYER_A
    } else if (player !== (board[y][x] === ECellState.PLAYER_A)) {
        return { group, dames }
    }

    group.push({ x, y })

    for (const neighbour of getNeighbourCoordinates(board, { x, y })) {
        if (
            board[neighbour.y][neighbour.x] === ECellState.EMPTY &&
            !dames.some((dame: ICellCoordinates) => neighbour.x === dame.x && neighbour.y === dame.y)
        ) {
            dames.push(neighbour)
        }
        const result = analizeGroup(neighbour, board, group, dames, player)
        group = result.group
        dames = result.dames
    }

    return { group, dames }
}

export function analizeNoMansLand(
    { x, y }: ICellCoordinates,
    state: IGameState,
    cells: ICellCoordinates[] = [],
    type: ECellState | null = null
): {
    cells: ICellCoordinates[],
    type: ECellState | null
} | false {
    if (state.board[y][x] !== ECellState.EMPTY) {
        return false
    }

    if (cells.some(({ x: _x, y: _y }: ICellCoordinates) => x === _x && y === _y)) {
        return { cells, type }
    }

    cells.push({ x, y })
    for (const cell of getNeighbourCoordinates(state.board, { x, y })) {
        const result = analizeNoMansLand(cell, state, cells, type)
        if (result) {
            if (type === null) {
                type = result.type
            } else if (type !== result.type) {
                // No man's land
                type = ECellState.EMPTY
            }
        } else {
            if (type === null) {
                type = state.board[cell.y][cell.x]
            } else if (type !== state.board[cell.y][cell.x]) {
                // No man's land
                type = ECellState.EMPTY
            }
        }
    }

    return { cells, type }
}

export function finishGame(state: IGameState, debug = false): IGameState {
    const _state = _.cloneDeep(state)
    _state.gameFinished = true

    // Fill in the territory
    const processedCells: ICellCoordinates[] = []
    for (let y = 0; y < _state.board.length; y++) {
        for (let x = 0; x < _state.board[0].length; x++) {
            if (_state.board[y][x] === null) {
                // Invalid coordinates
                continue
            }
            if (processedCells.some(({ x: _x, y: _y }) => x === _x && y === _y)) {
                // Already processed
                continue
            }
            const result = analizeNoMansLand({ x, y }, _state)
            if (result) {
                processedCells.push(...result.cells)
                if (result.type !== null && result.type !== ECellState.EMPTY) {
                    result.cells.forEach(({ x: _x, y: _y }) =>
                        _state.board[_y][_x] = result.type === null
                            ? ECellState.EMPTY  // Should never be selected
                            : result.type
                    )
                }
            } else {
                // This cell is not empty
                processedCells.push({ x, y })
            }
        }
    }

    // Count scores
    for (let y = 0; y < _state.board.length; y++) {
        for (let x = 0; x < _state.board[0].length; x++) {
            if (_state.board[y][x] === null) {
                // Invalid coordinates
                continue
            }
            if (_state.board[y][x] === ECellState.PLAYER_A) {
                _state.playerAScore++
            } else if (_state.board[y][x] === ECellState.PLAYER_B) {
                _state.playerBScore++
            }
        }
    }


    if (debug) {
        console.log('Game is finished')
        console.log(`Player A score: ${_state.playerAScore}`)
        console.log(`Player B score: ${_state.playerBScore}`)
        if (_state.playerAScore === _state.playerBScore) {
            console.log('Tie')
        } else if (_state.playerAScore > _state.playerBScore) {
            console.log('Player A wins')
        } else {
            console.log('Player B wins')
        }
    }

    return _state
}
