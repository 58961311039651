import { TBoard } from '../../types/IBoardState';
import cssVariables from '../../styles/hex.module.scss';
import Hexagon from "./Hexagon";

export interface HexRowProps {
    row: TBoard[number]
    y: number
    rowOuterClassNames: string[][]
    rowInnerClassNames: string[][]
    handleCellClick: Function
    handleCellHover: Function
}

const HexRow: React.FunctionComponent<HexRowProps> = ({
    row,
    rowOuterClassNames,
    rowInnerClassNames,
    handleCellClick,
    handleCellHover,
    y,
}) => {
    const hexHeight = Number(cssVariables.hexHeight)
    const hexWidth = Number(cssVariables.hexWidth)
    const hexMargin = Number(cssVariables.hexMargin)

    const step = hexHeight / 2 + hexMargin / 2
    const steps = (row.length - 1 - (row.length - row.filter(cell => cell !== null).length)) / 2
    const rowOffset = -1 * step * steps + hexMargin

    const rowCss = {
        marginBottom: rowOffset,
        gridTemplateColumns: `repeat(${row.length}, calc(${hexWidth}px * 1.5 + ${hexMargin}px))`
    }

    return <div className="hex-row" style={rowCss}>
        {row.map((cell, x) => {
            const margin = Math.abs((row.length - 1) / 2 - x) * (hexHeight / 2 + hexMargin / 2)
            const cellCss = {} as { [key: string]: any }
            if (cell !== null) {
                cellCss.marginTop = margin
            }

            return <Hexagon
                key={`Cell-${x}-${y}`}
                coords={{ x, y }}
                handleClick={handleCellClick}
                handleHover={handleCellHover}
                outerClassNames={rowOuterClassNames[x]}
                innerClassNames={rowInnerClassNames[x]}
                outerStyle={cellCss}
                innerStyle={{}}
                innerText=''
            />
        })}
    </div>
}

export default HexRow
