import { ICellCoordinates } from "../../types/IBoardState"

export interface HexagonProps {
    innerClassNames: string[]
    outerClassNames: string[]
    handleClick: Function
    handleHover: Function
    coords: ICellCoordinates
    innerText: string
    outerStyle: { [key: string]: any }
    innerStyle: { [key: string]: any }
}

const Hexagon: React.FunctionComponent<HexagonProps> = ({
    coords,
    handleClick,
    handleHover,
    innerClassNames = [],
    outerClassNames = [],
    outerStyle = {},
    innerStyle = {},
    innerText = '',
}) => {
    return <div
        className={'hexagon ' + outerClassNames.join(' ')}
        onMouseEnter={() => handleHover(coords)}
        onClick={() => handleClick(coords)}
        style={outerStyle}
    >
        <div
            className={'hexagon-inner ' + innerClassNames.join(' ')}
            style={innerStyle}
        >
            {innerText}
        </div>
    </div>
}

export default Hexagon
