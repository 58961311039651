import { render } from 'react-dom'
import App from './App'
import { GameState } from './store/GameState'
import './styles/main.scss'

const defaultGameState = new GameState({ size: 11 })
render(
    <App defaultGameState={defaultGameState} />,
    document.getElementById('root')
)
