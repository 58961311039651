export interface IRoomConnection {
    roomId: string
    party: 'host' | 'client'
}

export const createNewRoom = async (messageCallback: Function): Promise<IRoomConnection> => {
    const roomId = await (await fetch('/api/create-room', { method: 'POST'})).text()
    const connection = { roomId, party: 'host' as IRoomConnection['party'] }
    startListening(connection, messageCallback)
    return connection
}

export const connectToRoom = (roomId: string, messageCallback: Function): IRoomConnection => {
    const connection = { roomId, party: 'client' as IRoomConnection['party'] }
    startListening(connection, messageCallback)
    return connection
}

export const sendMessage = (message: string, connection: IRoomConnection) => {
    fetch(
        `/api/send-message?roomId=${connection.roomId}&party=${connection.party}&message=${message}`,
        { method: 'PUT' }
    )
}

async function startListening(connection: IRoomConnection, callback: Function) {
    const response = await fetch(`/api/long-poll?roomId=${connection.roomId}&party=${connection.party}`)
    const text = await response.text()
    if (response.status === 200) {
        if (text !== 'Timeout') {
            for (const message of JSON.parse(text)) {
                callback(message)
            }
        }
        startListening(connection, callback)
    } else {
        console.error(`Long polling error: ${text}`)
    }
}


