import { ECellState, ICellCoordinates, TBoard } from '../types/IBoardState'

export const makeBoard = (size: number): TBoard => {
    const out: TBoard = []
    const arrayDimention = sizeToArrayDimention(size)
    for (let y = 0; y < arrayDimention; y++) {
        out.push(new Array(arrayDimention).fill(null))
        const skip = Math.max(0, y - size + 1)
        for (let x = skip; x < arrayDimention - skip; x++) {
            out[y][x] = ECellState.EMPTY
        }
    }
    return out
}

export const sizeToArrayDimention = (size: number): number => size * 2 - 1
export const arrayDimentionToSize = (arrayDimention: number) => (arrayDimention - 1) / 2

export const getNeighbourCoordinates = (board: TBoard, { x, y }: ICellCoordinates): ICellCoordinates[] => {
    const out = [
        { x, y: y + 1 },
        { x, y: y - 1 },
        { x: x + 1, y },
        { x: x - 1, y },
    ]
    
    const size = arrayDimentionToSize(board.length)
    if (x === size) {
        out.push({ x: x + 1, y: y - 1 })
        out.push({ x: x - 1, y: y - 1 })
    } else if (x > size) {
        out.push({ x: x + 1, y: y - 1 })
        out.push({ x: x - 1, y: y + 1 })
    } else {
        out.push({ x: x + 1, y: y + 1 })
        out.push({ x: x - 1, y: y - 1 })
    }

    // `!=` intended to check for both `null` and `undefined`
    return out.filter(({ x, y }) => board[y] != null && board[y][x] != null)
}
